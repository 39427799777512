import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

import ApiService from '@/core/services/ApiService';
import { LogModuleStore, StoreError } from '@/models/StoreModel';
import { Actions, Mutations, Getters } from '@/store/enums/LogEnums';
import { Log } from '@/models/LogModel';

@Module
export default class LogModule extends VuexModule implements LogModuleStore {
  systemLog = {} as unknown as Log;

  actionError: StoreError | null = null;

  get [Getters.GET_ACTION_ERROR]() {
    return this.actionError;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  /**
   * Save
   * @param payload
   * @returns
   */
  @Action
  [Actions.LOG_RECENT_VIEWED_HISTORY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`agent/logs/viewed`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
