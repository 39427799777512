import ApiService from '@/core/services/ApiService';
import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';

import { TenantReview } from '@/models/TenantReviewModel';
import { TenantReviewModuleStore, StoreError } from '@/models/StoreModel';

import { Actions, Getters, Mutations } from '../enums/TenantReviewEnums';
import { AxiosRequestConfig } from 'axios';
import { Comment } from '@/models/CommentModel';
@Module
export default class TenantReviewModule
  extends VuexModule
  implements TenantReviewModuleStore
{
  actionError: StoreError | null = null;

  allTenantReview = [];
  tenantReview = {} as unknown as TenantReview;
  currentTenantReview = {} as unknown as TenantReview;
  tenantReviews = [];
  pendingTenantReviews = [];
  tenantReviewSummary = {} as any;
  tenantReviewListError: StoreError | null = null;
  tenantReviewLoading = false;
  tenantReviewListLoading = false;
  tenantLedger = '';
  tenantLedgerFile = '';
  supportDocuments = {} as unknown as {};
  uploadFileError: StoreError | null = null;
  agentAvatar = '';

  pendingFilter = false;
  agencyFilter = false;
  /**
   * Get all tenant reviews
   * @returns TenantReview
   */
  get [Getters.GET_ALL_TENANT_REVIEWS](): TenantReview[] {
    return this.allTenantReview;
  }

  /**
   * Get all tenant review
   * @returns TenantReview
   */
  get [Getters.GET_TENANT_REVIEWS](): TenantReview[] {
    return this.tenantReviews;
  }

  /**
   * Get all tenant review
   * @returns TenantReview
   */
  get [Getters.GET_TENANT_REVIEW](): TenantReview {
    return this.tenantReview as unknown as TenantReview;
  }

  /**
   * Get current tenant review
   * @returns TenantReview
   */
  get [Getters.GET_CURRENT_TENANT_REVIEW](): TenantReview {
    return this.currentTenantReview as unknown as TenantReview;
  }

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_TENANT_REVIEW_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource list error
   */
  get [Getters.GET_TENANT_REVIEW_LIST_ERROR](): StoreError {
    return this.tenantReviewListError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_REVIEW_LIST_LOADING](): StoreError {
    return this.tenantReviewListLoading as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_REVIEW_LOADING](): StoreError {
    return this.tenantReviewLoading as unknown as StoreError;
  }

  get [Getters.GET_TENANT_REVIEW_LEDGER](): string {
    return this.tenantLedger;
  }

  get [Getters.GET_TENANT_REVIEW_LEDGER_FILE](): any {
    return this.tenantLedgerFile;
  }

  get [Getters.GET_TENANT_REVIEW_SUPPORT_DOCUMENTS](): any {
    return this.supportDocuments;
  }

  get [Getters.GET_UPLOAD_FILE_ERROR](): StoreError {
    return this.uploadFileError as unknown as StoreError;
  }

  get [Getters.GET_AGENT_AVATAR](): string {
    return this.agentAvatar;
  }

  get [Getters.GET_REVIEWS_FILTER_TO_AGENCY]() {
    return this.agencyFilter;
  }

  get [Getters.GET_PENDING_REVIEWS_FILTER_TO_AGENCY]() {
    return this.pendingFilter;
  }

  get [Getters.GET_PENDING_TENANT_REVIEWS](): TenantReview[] {
    return this.pendingTenantReviews;
  }

  get [Getters.GET_TENANT_REVIEW_SUMMARY](): TenantReview[] {
    return this.tenantReviewSummary;
  }

  @Mutation
  [Mutations.SET_PENDING_REVIEWS_FILTER_TO_AGENCY](bool) {
    this.pendingFilter = bool;
  }

  @Mutation
  [Mutations.SET_REVIEWS_FILTER_TO_AGENCY](bool) {
    this.agencyFilter = bool;
  }

  @Mutation
  [Mutations.SET_UPLOAD_FILE_ERROR](errors) {
    this.uploadFileError = errors as StoreError;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_LEDGER_FILE](tenantLedgerFile) {
    this.tenantLedgerFile = tenantLedgerFile;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_SUPPORT_DOCUMENTS](supportDocuments) {
    this.supportDocuments = supportDocuments;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_LEDGER](tenantLedger) {
    this.tenantLedger = tenantLedger;
  }

  @Mutation
  [Mutations.SET_ALL_TENANT_REVIEWS](tenantReviews) {
    this.allTenantReview = tenantReviews;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW](tenantReview) {
    this.tenantReview = tenantReview;
  }

  @Mutation
  [Mutations.SET_CURRENT_TENANT_REVIEW](tenantReview: TenantReview) {
    this.currentTenantReview = tenantReview;
  }

  @Mutation
  [Mutations.SET_PENDING_TENANT_REVIEWS](tenantReviews) {
    this.pendingTenantReviews = tenantReviews;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEWS](tenantReviews) {
    this.tenantReviews = tenantReviews;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_ACTION_ERROR](errors) {
    this.actionError = errors as StoreError;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_LIST_ERROR](errors) {
    this.tenantReviewListError = errors as StoreError;
  }

  @Mutation
  [Mutations.SET_AGENT_AVATAR](avatar) {
    this.agentAvatar = avatar;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_SUMMARY](value) {
    this.tenantReviewSummary = value;
  }

  @Action
  [Actions.TOOGLE_REVIEW_AGENCY_FILTER](filterFlag) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_REVIEWS_FILTER_TO_AGENCY, filterFlag);
      resolve();
    });
  }

  @Action
  [Actions.TOOGLE_PENDING_REVIEW_AGENCY_FILTER](filterFlag) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(
        Mutations.SET_PENDING_REVIEWS_FILTER_TO_AGENCY,
        filterFlag
      );
      resolve();
    });
  }

  @Action
  [Actions.FETCH_ALL_TENANT_REVIEWS](tenantId) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/tenants/${tenantId}/reviews`, '', {
        params: { returnAll: true },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_TENANT_REVIEWS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_LIST_ERROR,
            response.data.errors || response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REVIEW_BY_REFERENCE_NO](refNo) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/tenants/reviews', refNo)
        .then((response) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW, response.data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_LIST_ERROR,
            response.data.errors || response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TENANT_REVIEW](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/tenants/reviews', payload)
        .then((response) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW, response.data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SEND_TENANT_REVIEW_TO_EMAILS](params) {
    return new Promise<void>((resolve, reject) => {
      const formData = new FormData();
      formData.append('emails', JSON.stringify(params.emails));
      formData.append('refNo', params.refNo);

      ApiService.post(`agent/tenants/reviews/${params.refNo}/send`, formData)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_TENANT_REVIEW_LEDGER](payload) {
    const formData = new FormData();
    formData.append('ledger_file', payload);

    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/tenants/reviews/ledger', formData)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW_LEDGER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_UPLOAD_FILE_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_TENANT_REVIEW_SUPPORT_DOCUMENTS](params) {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('refNo', params.refNo);

    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/tenants/reviews/support', formData)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_UPLOAD_FILE_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REVIEW_LEDGER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `agent/tenants/${payload.tenantId}/reviews/${payload.refId}/ledger`,
        '',
        {
          responseType: 'arraybuffer',
        }
      )
        .then((response) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_LEDGER_FILE,
            response.data
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REVIEW_SUPPORT_DOCUMENTS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/reviews/${payload.refNo}/documents`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_SUPPORT_DOCUMENTS,
            data.data.documents
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_SUPPORT_DOCUMENT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(
        `agent/reviews/${payload.refNo}/documents/${payload.filename}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  // COMMENTS
  comments = [] as Comment;

  get [Getters.GET_COMMENTS](): any {
    return this.comments;
  }

  @Mutation
  [Mutations.SET_COMMENTS](data: any) {
    this.comments = data;
  }

  @Action
  [Actions.SUBMIT_COMMENT](payload: any) {
    const data = {
      comment: payload.comment,
      parent_id: payload.parent_id,
    } as any;
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`agent/tenants/reviews/${payload.ref_id}/comments`, data)
        .then(({ data }) => {
          resolve();
        })
        .catch((response) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_COMMENTS](refId: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/tenants/reviews/${refId}/comments`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMMENTS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  // STREET VIEW IMAGE
  // COMMENTS
  streetViewImage = null as any;

  get [Getters.GET_STREETVIEW_IMAGE](): any {
    return this.streetViewImage;
  }

  @Mutation
  [Mutations.SET_STREETVIEW_IMAGE](data: any) {
    this.streetViewImage = data;
  }

  @Action
  [Actions.FETCH_STREETVIEW_IMAGE](params: any) {
    return new Promise<void>((resolve, reject) => {
      const axiosRequestConfig = {
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;
      ApiService.query(
        `agent/tenants/reviews/${params.reviewRefNo}/street`,
        axiosRequestConfig
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }

  // RECENT ADDED REFERENCES
  recentReferences = [];

  get [Getters.GET_RECENT_SUBMITTED_REFERENCES](): any {
    return this.recentReferences;
  }

  @Mutation
  [Mutations.SET_RECENT_SUBMITTED_REFERENCES](data: any) {
    this.recentReferences = data;
  }

  @Action
  [Actions.FETCH_RECENT_SUBMITTED_REFERENCES](params = {}) {
    return new Promise<void>((resolve, reject) => {
      ApiService.parameters(params);
      ApiService.get(`agent/references/submitted`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_RECENT_SUBMITTED_REFERENCES,
            data.data
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PENDING_TENANT_REVIEW](params = {}) {
    return new Promise<void>((resolve, reject) => {
      ApiService.parameters(params);
      ApiService.get(`agent/references/pending`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PENDING_TENANT_REVIEWS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  // STREET VIEW IMAGE
  // COMMENTS
  googleMap = null as any;

  get [Getters.GET_GOOGLEMAP_IMAGE](): any {
    return this.googleMap;
  }

  @Mutation
  [Mutations.SET_GOOGLEMAP_IMAGE](data: any) {
    this.googleMap = data;
  }

  @Action
  [Actions.FETCH_GOOGLEMAP_IMAGE](params: any) {
    return new Promise<void>((resolve, reject) => {
      const axiosRequestConfig = {
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;
      ApiService.query(
        `agent/tenants/reviews/${params.reviewRefNo}/map`,
        axiosRequestConfig
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_TENANT_REVIEW_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TENANT_REVIEW](payload: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `agent/tenants/reviews/${payload.reviewRefNo}`,
        payload.values
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_TENANT_REVIEW, data.data);
          resolve(data);
        })
        .catch((response) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.PUBLISH_TENANT_REVIEW](payload: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `agent/tenants/reviews/${payload.reviewRefNo}/publish`,
        payload.values
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_TENANT_REVIEW, data.data);
          resolve(data);
        })
        .catch((response) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.TOGGLE_TENANT_REVIEW_FLAG](payload: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`agent/tenants/reviews/${payload.refNo}/flag`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_TENANT_REVIEW, data.data);
          resolve(data);
        })
        .catch((response) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REVIEW_SUMMARY](params = {}) {
    return new Promise<void>((resolve, reject) => {
      ApiService.parameters(params);
      ApiService.get(`agent/references/summary`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW_SUMMARY, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }
  @Action
  [Actions.SAVE_TENANT_REPORT_PDF](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`agent/reviews/${params.refNo}/report`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }
}
