enum Actions {
  ADD_BUSINESS = 'addBusiness',
  UPDATE_BUSINESS = 'updateBusiness',
  UPDATE_BUSINESS_LINKS = 'updateBusinessLinks',
  FETCH_BUSINESS_LOGO = 'fetchBusinessLogo',
  FETCH_AGENT_BUSINESS_LOGO = 'fetchAgentBusinessLogo',
  SEARCH_AGENCY = 'searchAgency',
}

enum Mutations {
  SET_ACTION_ERROR = 'setBusinessActionError',
  SET_BUSINESS_LOGO = 'setBusinessLogo',
  SET_AGENT_BUSINESS_LOGO = 'setAgentBusinessLogo',
}

enum Getters {
  GET_BUSINESS_LOGO = 'getBusinessLogo',
  GET_ACTION_ERROR = 'getBusinessActionError',
  GET_AGENT_BUSINESS_LOGO = 'getAgentBusinessLogo',
}

export { Actions, Mutations, Getters };
