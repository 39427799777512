import ApiService from '@/core/services/ApiService';
import { StoreError, ReferenceRequestModuleStore } from '@/models/StoreModel';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  Actions,
  Getters,
  Mutations,
} from '@/store/enums/ReferenceRequestEnums';
import { ReferenceRequest } from '@/models/ReferenceRequestModel';

@Module
export default class ReferenceRequestModule
  extends VuexModule
  implements ReferenceRequestModuleStore
{
  actionError: StoreError | null = null;

  allReferenceRequests = [];
  referenceRequest = {} as unknown as ReferenceRequest;
  referenceRequests = [];

  requestError: StoreError | null = null;
  routeError: StoreError | null = null;
  requestLoading = false;

  tenantReference = {} as unknown as ReferenceRequest;
  currentReferenceRequest = {} as unknown as ReferenceRequest;

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  get [Getters.GET_ROUTE_ACTION_ERROR](): StoreError {
    return this.routeError as unknown as StoreError;
  }

  get [Getters.GET_TENANT_REFERENCE](): ReferenceRequest {
    return this.tenantReference;
  }

  get [Getters.GET_CURRENT_REFERENCE_REQUEST](): ReferenceRequest {
    return this.currentReferenceRequest;
  }

  @Mutation
  [Mutations.SET_CURRENT_REFERENCE_REQUEST](reference) {
    this.currentReferenceRequest = reference;
  }

  @Mutation
  [Mutations.SET_TENANT_REFERENCE](data) {
    this.tenantReference = data;
  }

  @Mutation
  [Mutations.SET_ROUTE_ACTION_ERROR](error) {
    this.routeError = error;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](actionError) {
    this.actionError = actionError;
  }

  @Action
  [Actions.SEND_REQUEST_TENANT_REFERENCE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/tenants/reviews/requests', payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REFERENCE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/tenants/reviews/requests/${payload.id}`)
        .then((response) => {
          this.context.commit(Mutations.SET_TENANT_REFERENCE, response.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CHECK_TENANT_REFERENCE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`agent/tenant/reference`, payload)
        .then((response) => {
          this.context.commit(Mutations.SET_TENANT_REFERENCE, response.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.REMOVE_STORED_REFERENCE]() {
    localStorage.removeItem('reference_request');
  }
}
