import ApiService from '@/core/services/ApiService';

import { Module, Action, VuexModule } from 'vuex-module-decorators';
import { Actions } from '@/store/enums/AssetEnums';

import { AssetModuleStore, StoreError } from '@/models/StoreModel';

@Module
export default class AssetModule
  extends VuexModule
  implements AssetModuleStore
{
  filename = '' as string;
  filesize = '' as string;
  date_created = '' as string;
  date_modified = '' as string;

  actionError = {} as unknown as StoreError;

  @Action
  [Actions.GENERATE_FILE_URL](file: string) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/marketing/open-house?download=true')
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }
}
