import store from '@/store';
import { Actions } from '@/store/enums/TenantEnums';

const SubmitReviewRouteGuard = {
  main: () => async (to, from, next) => {
    if (typeof from.params.tenantId !== 'undefined') {
      await store.dispatch(Actions.FETCH_TENANT, from.params.tenantId);
    }

    next();
  },
};

export default SubmitReviewRouteGuard;
