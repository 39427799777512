import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import store from '@/store';
import { Actions, Getters, Mutations } from '@/store/enums/AuthEnums';

const ProtectedRouteGuard = {
  isLoggedIn: () => async (to, from, next) => {
    const sessionToken = JwtService.getToken();

    ApiService.setAuthorization(sessionToken);
    return await store
      .dispatch(Actions.VERIFY_AUTH)
      .then(async (data) => {
        const authStatus = await store.getters[Getters.GET_AUTH_STATUS];

        if (!authStatus && to.name == 'view-tenant-review') {
          return next({
            name: 'sign-in-options',
            query: { ref: to.params.refNo },
          });
        }

        if (!authStatus) {
          return next({ name: 'sign-in' });
        }

        await store.dispatch(Actions.FETCH_AUTH_USER);

        return next();
      })
      .catch(async () => {
        store.commit(
          Mutations.SET_AUTH_ROUTE_ERROR,
          'Please login to continue.'
        );

        const authStatus = await store.getters[Getters.GET_AUTH_STATUS];

        if (!authStatus && to.name == 'view-tenant-review') {
          return next({
            name: 'sign-in-options',
            query: { ref: to.params.refNo },
          });
        }

        return next({ name: 'sign-in' });
      });
  },
  main: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const urlQuery = await to.query;

    console.log('user', {
      user: user,
      length: Object.entries(user).length,
    });

    //check if user store is exsiting

    if (typeof user === 'undefined' || Object.entries(user).length === 0) {
      return next({ name: 'sign-in' });
    }

    if (
      typeof user === 'object' &&
      !user.roles.includes('principal') &&
      !user.roles.includes('pm')
    ) {
      return next({ name: 'sign-in' });
    }

    if (to.name === 'request-account-verification-mail') {
      return next();
    }

    // user is not verified
    if (user.verified_at === null) {
      return next({ name: 'request-account-verification-mail' });
    }

    // profile not completed
    if (
      user.profile_completed_at === null &&
      user.skip_complete_profile === 'no'
    ) {
      return next({ name: 'complete-account-profile', query: urlQuery });
    }

    // force dashboard for skip profile
    if (
      to.name === 'complete-account-profile' &&
      user.skip_complete_profile === 'yes'
    ) {
      return next({ name: 'dashboard', query: urlQuery });
    }

    // trap negative
    if (
      to.name === 'dashboard' &&
      user.profile_completed_at !== null &&
      user.skip_complete_profile === 'yes' &&
      user.verified_at !== null
    ) {
      return next({ query: urlQuery });
    }

    return next();
  },
  verified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const urlQuery = await to.query;

    //check if user store is exsiting
    if (typeof user.id === 'undefined') {
      return next({ name: 'sign-in' });
    }

    if (!user.roles.includes('principal') && !user.roles.includes('pm')) {
      return next({ name: 'sign-in' });
    }

    if (to.name === 'request-account-verification-mail') {
      return next();
    }

    if (to.name === 'dashboard') {
      return next();
    }
    return next();
  },
  isVerified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const urlQuery = await to.query;

    //check if user store is exsiting
    if (typeof user.id === 'undefined') {
      return next({ name: 'sign-in' });
    }

    if (!user.roles.includes('principal') && !user.roles.includes('pm')) {
      return next({ name: 'sign-in' });
    }

    if (typeof user.verified_at !== 'undefined' && user.verified_at === null) {
      return next({ name: 'request-account-verification-mail' });
    }

    // if (
    //   typeof user.profile_completed_at !== 'undefined' &&
    //   user.profile_completed_at === null
    // ) {
    //   return next({ name: 'complete-account-profile', query: urlQuery });
    // }

    return next();
  },
  isNotVerified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const urlQuery = await to.query;

    if (!user.roles.includes('principal') && !user.roles.includes('pm')) {
      return next({ name: 'sign-in' });
    }

    if (typeof user.verified_at !== 'undefined' && user.verified_at !== null) {
      return next({ name: 'complete-account-profile', query: urlQuery });
    }

    return next();
  },
  isAdminLevel: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const allowedUserRoles = ['principal'];

    if (typeof user.roles !== 'undefined') {
      Array.from(user.roles).forEach((role: string | unknown) => {
        if (allowedUserRoles.includes(role as unknown as string)) {
          return next();
        }
      });
    }

    return next({ name: 'dashboard' });
  },
};

export default ProtectedRouteGuard;
