enum Actions {
  SEND_REQUEST_TENANT_REFERENCE = 'sendRequestTenantReference',
  CHECK_TENANT_REFERENCE = 'checkTenantReference',
  FETCH_TENANT_REFERENCE = 'fetchTenantReference',
  REMOVE_STORED_REFERENCE = 'removeStoredReference',
}

enum Mutations {
  SET_CURRENT_REFERENCE_REQUEST = 'setCurrentReferenceRequest',
  SET_ACTION_ERROR = 'setReferenceActionError',
  SET_ROUTE_ACTION_ERROR = 'setRouetActionError',
  SET_TENANT_REFERENCE = 'setTenantReference',
}

enum Getters {
  GET_CURRENT_REFERENCE_REQUEST = 'getCurrentReferenceRequest',
  GET_ACTION_ERROR = 'getReferenceActionError',
  GET_ROUTE_ACTION_ERROR = 'getRouteActionError',
  GET_TENANT_REFERENCE = 'getTenantReference',
}

export { Actions, Mutations, Getters };
