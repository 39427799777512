import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import store from '@/store';
import { Getters } from '@/store/enums/AuthEnums';
import { Actions, Mutations } from '@/store/enums/ReferenceRequestEnums';

const RequestTenantReferenceGuard = {
  main: () => async (to, from, next) => {
    const sessionToken = JwtService.getToken();
    const referenceNo = await to.query.ref;

    ApiService.setAuthorization(sessionToken);

    if (
      (await from.name) === undefined &&
      (await to.name) === 'submit-tenant-review' &&
      referenceNo === undefined
    ) {
      console.log('bypass');
      return next();
    }

    if (!referenceNo && (await to.name) !== 'submit-tenant-review') {
      return next();
    }

    //reset reference_request value
    localStorage.removeItem('reference_request');

    // validate tenant reference
    // validate if submitted
    return await store
      .dispatch(Actions.CHECK_TENANT_REFERENCE, {
        id: referenceNo,
      })
      .then(async () => {
        localStorage.setItem('reference_request', to.query.ref);

        const authStatus = await store.getters[Getters.GET_AUTH_STATUS];

        if (to.name == 'submit-tenant-review' && !authStatus) {
          return next({
            name: 'tenant-reference-request',
            query: { ref: to.query.ref },
          });
        }

        return next();
      })
      .catch(async () => {
        store.commit(
          Mutations.SET_ROUTE_ACTION_ERROR,
          'Invalid Reference Request'
        );

        return next();
      });
  },
  refVerified: () => async (to, from, next) => {
    if (to.query.ref) {
      localStorage.setItem('reference_request', to.query.ref);
    } else {
      return next({ name: 'sign-in' });
    }

    next();
  },
  hasRequestRef: () => async (to, from, next) => {
    const ref = localStorage.getItem('reference_request');

    console.log('hasRequestRef', {
      from: from,
      to: to,
    });

    if (ref) {
      if (from.name === 'complete-account-profile' && to.name === 'dashboard') {
        return next({ name: 'submit-tenant-review', query: { ref: ref } });
      }

      if (to.name === 'submit-tenant-review') {
        return next({ name: 'submit-tenant-review', query: { ref: ref } });
      }
    }

    next();
  },
};

export default RequestTenantReferenceGuard;
