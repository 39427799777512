enum Actions {
  FETCH_CURRENT_SUBSCRIPTION = 'fetchCurrentSubscription',
  CANCEL_SUBSCRIPTION_AT_PERIOD_END = 'cancelSubscriptionAtPeriodEnd',
  CANCEL_SUBSCRIPTION_IMMEDIATELY = 'cancelSubscriptionImmediately',
  FETCH_SUBSCRIPTIONS = 'fetchSubscriptions',
}

enum Mutations {
  SET_SUBSCRIPTIONS = 'setSubscriptions',
  SET_SUBSCRIPTION = 'setSubscription',
  SET_ACTION_ERROR = 'setSubscriptionActionError',
}

enum Getters {
  GET_SUBSCRIPTIONS = 'getSubscriptions',
  GET_SUBSCRIPTION = 'getSubscription',
  GET_ACTION_ERROR = 'getSubscriptionActionError',
}

export { Actions, Mutations, Getters };
