enum Actions {
  DO_CHECKOUT = 'doCheckout',
  CANCEL_CHECKOUT = 'cancelCheckout',
  CREATE_CUSTOMER = 'createCustomer',
}

enum Mutations {
  SET_CUSTOMER = 'setCustomer',
  SET_LINE_ITEM = 'setLineItem',
  SET_ACTION_ERROR = 'setActionError',
}

enum Getters {
  GET_CUSTOMER = 'getCustomerId',
  GET_LINE_ITEM = 'getCustomerId',
  GET_LINE_ITEMS = 'getLineItems',
  GET_ACTION_ERROR = 'getActionError',
}

export { Actions, Mutations, Getters };
