enum Actions {
  // action types
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  FETCH_AUTH_USER = 'fetchAuthUser',
  UPDATE_USER_PROFILE = 'updateUserProfile',
  NEW_PASSWORD = 'newPassword',
  CHANGE_PASSWORD = 'changePassword',
  CLEAR_AUTH_USER = 'clearAuthUser',
  SEND_MOBILE_CODE = 'sendMobileCode',
  VERIFIY_MOBILE = 'verifyMobile',
  UPLOAD_PROFILE_PICTURE = 'uploadProfilePicture',
  REMOVE_PROFILE_PICTURE = 'removeProfilePicture',
  SUBMIT_COMPLETE_PROFILE = 'submitCompleteProfile',
}

enum Mutations {
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_AUTH_STATUS = 'setAuthStatus',
  SET_AUTH_TOKEN = 'setAuthToken',
  SET_AUTH_ERROR = 'setAuthError',
  SET_AUTH_USER = 'setAuthUser',
  SET_AUTH_PASSWORD = 'setPassword',
  SET_AUTH_USER_PROFILE_PICTURE = 'setProfilePicture',
  SET_AUTH_USER_ERROR = 'setAuthUserError',
  SET_AUTH_ROUTE_ERROR = 'setAuthRouteError',
  SET_AUTH_USER_ACTION_ERROR = 'setUserActionError',
  SET_MOBILE_IS_VERIFIED = 'setMobileIsVerified',
}
// mutation types

enum Getters {
  GET_AUTH = 'getAuth',
  GET_AUTH_STATUS = 'getAuthStatus',
  GET_AUTH_TOKEN = 'getAuthToken',
  GET_AUTH_USER = 'getAuthUser',
  GET_AUTH_USER_FULL_NAME = 'getAuthUserFullname',
  GET_AUTH_USER_PROFILE_PICTURE = 'getAuthUserProfilePicture',
  GET_AUTH_ERROR = 'getAuthError',
  GET_AUTH_ROUTE_ERROR = 'getAuthRouteError',
  GET_AUTH_USER_ACTION_ERROR = 'getAuthUserActionError',
  GET_MOBILE_IS_VERIFIED = 'getMobileIsVerified',
}

export { Actions, Mutations, Getters };
