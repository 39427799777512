import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import UserModule from '@/store/modules/UserModule';
import TenantModule from '@/store/modules/TenantModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import Resources from '@/store/modules/ResourcesModule';
import TenantReviewModule from '@/store/modules/TenantReviewModule';
import ReviewQuestionModule from '@/store/modules/ReviewQuestionModule';
import ReferenceRequestModule from '@/store/modules/ReferenceRequestModule';
import SystemsModule from '@/store/modules/SystemsModule';
import VerifyAccountModule from '@/store/modules/VerifyAccountModule';
import PaymentModule from '@/store/modules/PaymentModule';
import PlanModule from '@/store/modules/PlanModule';
import PrincipalModule from '@/store/modules/PrincipalModule';
import BusinessModule from '@/store/modules/BusinessModule';
import ForgotPasswordModule from '@/store/modules/ForgotPasswordModule';

import TemplatesModule from '@/store/modules/TemplatesModule';
import RemindersModule from '@/store/modules/RemindersModule';
import ContactSupportModule from '@/store/modules/ContactSupportModule';

import SubscriptionModule from '@/store/modules/SubscriptionModule';
import InvoiceModule from '@/store/modules/InvoiceModule';
import NotificationModule from '@/store/modules/NotificationModule';
import AssetModule from '@/store/modules/AssetModule';
import LogModule from '@/store/modules/LogModule';

config.rawError = true;

const store = createStore({
  modules: {
    Resources,
    BodyModule,
    ConfigModule,
    BreadcrumbsModule,
    UserModule,
    AuthModule,
    ForgotPasswordModule,
    TenantModule,
    ReferenceRequestModule,
    TenantReviewModule,
    ReviewQuestionModule,
    SystemsModule,
    VerifyAccountModule,
    PaymentModule,
    PlanModule,
    PrincipalModule,
    BusinessModule,
    TemplatesModule,
    RemindersModule,
    ContactSupportModule,
    SubscriptionModule,
    InvoiceModule,
    NotificationModule,
    AssetModule,
    LogModule,
  },
});

export default store;
