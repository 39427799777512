import store from '@/store';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { Getters as RRGetters } from '@/store/enums/ReferenceRequestEnums';

const SubscriptionRouteGuard = {
  hasSubscription: () => async (to, from, next) => {
    // bypass requested tenant reference submission
    const referenceRequest = await store.getters[
      RRGetters.GET_CURRENT_REFERENCE_REQUEST
    ];
    const ref = localStorage.getItem('reference_request');
    if (to.name === 'submit-tenant-review' && to.query.ref === ref) {
      return next();
    }

    //if the auth user has subscription
    const user = await store.getters[AuthGetters.GET_AUTH_USER];

    if (
      typeof user.subscription === 'undefined' ||
      user.subscription.length === 0
    ) {
      return next({ name: 'plan-page' });
    }

    const hasActiveSubscription = user.subscription.filter(
      (subscription: any) => {
        return subscription?.stripe_status === 'active';
      }
    );

    console.log('hasActiveSubscription', hasActiveSubscription);

    if (!hasActiveSubscription.length) {
      return next({ name: 'plan-page' });
    }

    next();
  },
};

export default SubscriptionRouteGuard;
