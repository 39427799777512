enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_SYSTEM_ERROR = 'setSystemError',
  SET_DEFAULT_BACKGROUND_COLOR = 'setDefaultBackgroundColor',
  SET_DEFAULT_TEXT_COLOR = 'setDefaultTextColor',
  SET_DEFAULT_AVATAR_BGCOLOR = 'setDefaultAvatarBGColor',
}

enum Getters {
  // mutation types
  GET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  GET_BREADCRUMB_MUTATION = 'getBreadcrumbMutation',
  GET_LAYOUT_CONFIG = 'getLayoutConfig',
  GET_SYSTEM_ERROR = 'getSystemError',
  GET_DEFAULT_BACKGROUND_COLOR = 'getDefaultBackgroundColor',
  GET_DEFAULT_TEXT_COLOR = 'getDefaultTextColor',
  GET_DEFAULT_AVATAR_BGCOLOR = 'getDefaultAvatarBGColor',
}

export { Actions, Mutations, Getters };
