enum Actions {
  FETCH_ALL_TENANT_REVIEWS = 'retrieveTenantReviews',
  FETCH_TENANT_REVIEW = 'fetchTenantReview',
  FETCH_TENANT_REVIEW_BY_REFERENCE_NO = 'fetchTenantReviewByRefNo',
  FETCH_PENDING_TENANT_REVIEW = 'fetchPendingTenantReview',
  CREATE_TENANT_REVIEW = 'createTenantReview',
  UPLOAD_TENANT_REVIEW_LEDGER = 'uploadTenantReviewLedger',
  UPLOAD_TENANT_REVIEW_SUPPORT_DOCUMENTS = 'uploadTenantReviewSupportDocuments',
  FETCH_TENANT_REVIEW_SUPPORT_DOCUMENTS = 'fetchTenantReviewSupportDocuments',
  SEND_TENANT_REVIEW_TO_EMAILS = 'sendTenantReviewToEmails',
  FETCH_TENANT_REVIEW_LEDGER = 'fetchTenantReviewLedger',
  FETCH_AGENT_AVATAR = 'fetchAgentAvatar',
  FETCH_COMMENTS = 'fetchComments',
  SUBMIT_COMMENT = 'submitComment',
  FETCH_STREETVIEW_IMAGE = 'fetchStreetViewImage',
  FETCH_RECENT_SUBMITTED_REFERENCES = 'fetchRecentSubmittedReferences',
  FETCH_GOOGLEMAP_IMAGE = 'fetchGoogleMapImage',
  TOOGLE_REVIEW_AGENCY_FILTER = 'toggleReviewAgencyFilter',
  TOOGLE_PENDING_REVIEW_AGENCY_FILTER = 'togglePendingReviewAgencyFilter',
  UPDATE_TENANT_REVIEW = 'updateTenantReview',
  PUBLISH_TENANT_REVIEW = 'publishTenantReview',
  DELETE_SUPPORT_DOCUMENT = 'deleteSupportDocument',
  TOGGLE_TENANT_REVIEW_FLAG = 'toggleTenantReviewFlag',
  FETCH_TENANT_REVIEW_SUMMARY = 'fetchTenantReviewSummary',
  SAVE_TENANT_REPORT_PDF = 'saveTenantReportPDF',
}

enum Mutations {
  SET_ALL_TENANT_REVIEWS = 'setAllTenantReviews',
  SET_CURRENT_TENANT_REVIEW = 'setCurrentTenantReview',
  SET_PENDING_TENANT_REVIEWS = 'setPendingTenantReviews',
  SET_TENANT_REVIEW = 'setTenantReview',
  SET_TENANT_REVIEWS = 'setTenantReviews',
  SET_TENANT_REVIEW_ACTION_ERROR = 'setTenantReviewActionError',
  SET_TENANT_REVIEW_LIST_ERROR = 'setTenantReviewListError',
  SET_TENANT_REVIEW_LIST_LOADING = 'setTenantReviewListLoading',
  SET_TENANT_REVIEW_LOADING = 'setTenantReviewLoading',
  SET_TENANT_REVIEW_LEDGER = 'setTenantReviewLedger',
  SET_TENANT_REVIEW_LEDGER_FILE = 'setTenantReviewLedgerFile',
  SET_TENANT_REVIEW_SUPPORT_DOCUMENTS = 'setTenantReviewSupportDocuments',
  SET_UPLOAD_FILE_ERROR = 'setUploadFileError',
  SET_AGENT_AVATAR = 'setAgentAvatar',
  SET_COMMENTS = 'setComments',
  SET_STREETVIEW_IMAGE = 'setStreetViewImage',
  SET_RECENT_SUBMITTED_REFERENCES = 'setRecentSubmittedReferences',
  SET_GOOGLEMAP_IMAGE = 'setGoogleMapImage',
  SET_REVIEWS_FILTER_TO_AGENCY = 'setAgencyFilter',
  SET_PENDING_REVIEWS_FILTER_TO_AGENCY = 'setPendingFilter',
  SET_TENANT_REVIEW_SUMMARY = 'setTenantReviewSummary',
}

enum Getters {
  GET_ALL_TENANT_REVIEWS = 'getAllTenantReviews',
  GET_CURRENT_TENANT_REVIEW = 'getCurrentTenantReview',
  GET_PENDING_TENANT_REVIEWS = 'getPendingTenantReviews',
  GET_TENANT_REVIEWS = 'getTenantReviews',
  GET_TENANT_REVIEW = 'getTenantReview',
  GET_TENANT_REVIEW_ACTION_ERROR = 'getTenantReviewActionError',
  GET_TENANT_REVIEW_LIST_ERROR = 'getTenantReviewListError',
  GET_TENANT_REVIEW_LIST_LOADING = 'getTenantReviewListLoading',
  GET_TENANT_REVIEW_LOADING = 'getTenantReviewLoading',
  GET_TENANT_REVIEW_LEDGER = 'getTenantReviewLedger',
  GET_TENANT_REVIEW_LEDGER_FILE = 'getTenantReviewLedgerFile',
  GET_TENANT_REVIEW_SUPPORT_DOCUMENTS = 'getTenantReviewSupportDocuments',
  GET_UPLOAD_FILE_ERROR = 'getUploadFileError',
  GET_AGENT_AVATAR = 'getAgentAvatar',
  GET_COMMENTS = 'getComments',
  GET_STREETVIEW_IMAGE = 'getStreetViewImage',
  GET_RECENT_SUBMITTED_REFERENCES = 'getRecentSubmittedReferences',
  GET_GOOGLEMAP_IMAGE = 'getGoogleMapImage',
  GET_REVIEWS_FILTER_TO_AGENCY = 'getAgencyFilter',
  GET_PENDING_REVIEWS_FILTER_TO_AGENCY = 'getPendingFilter',
  GET_TENANT_REVIEW_SUMMARY = 'getTenantReviewSummary',
}

export { Actions, Mutations, Getters };
