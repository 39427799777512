enum Actions {
  FETCH_REMINDER_LIST = 'fetchReminderList',
  UPDATE_REMINDER = 'updateReminder',
  CREATE_REMINDER = 'createReminder',
  FETCH_REMINDERS_BY_NAME = 'fetchReminderByName',
  FETCH_REMINDER_BY_ID = 'fetchReminderById',
  FETCH_REMINDER_BY_DAY = 'fetchReminderByDay',
  DELETE_REMINDER = 'deleteReminder',
  FETCH_PLACEHOLDERS = 'fetchReminderPlaceholders',
}

enum Mutations {
  SET_PLACEHOLDERS = 'setReminderPlaceHolders',
  SET_TITLE = 'setReminderTitle',
  SET_REMINDER_LIST = 'setReminderList',
  SET_REMINDER = 'setReminder',
  SET_REMINDERS = 'setReminders',
  SET_ACTION_ERROR = 'setReminderActionError',
}

enum Getters {
  GET_PLACEHOLDERS = 'getReminderPlaceHolders',
  GET_TITLE = 'getReminderTitle',
  GET_REMINDER_LIST = 'getReminderList',
  GET_REMINDER = 'getReminder',
  GET_REMINDERS = 'getRemindersByName',
  GET_ACTION_ERROR = 'getReminderActionError',
}

export { Actions, Mutations, Getters };
