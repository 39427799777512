import ApiService from '@/core/services/ApiService';
import { AxiosRequestConfig } from 'axios';
import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';

import { PrincipalModuleStore, StoreError } from '@/models/StoreModel';
import { Actions, Getters, Mutations } from '@/store/enums/PrincipalEnums';
import { Mutations as AuthMutations } from '@/store/enums/AuthEnums';
import { Principal } from '@/models/PrincipalModel';
import { Mutations as UserMutations } from '@/store/enums/UserEnums';

@Module
export default class PrincipalModule
  extends VuexModule
  implements PrincipalModuleStore
{
  actionError: StoreError | null = null;

  allPrincipals = [];
  principal = {} as unknown as Principal;
  principals = [];

  principalListError = null;
  principalLoading = false;
  principalListLoading = false;

  /**
   * Get all Principal
   * @returns Principal
   */
  get [Getters.GET_ALL_PRINCIPALS](): Principal[] {
    return this.allPrincipals;
  }

  /**
   * Get all Principal
   * @returns Principal
   */
  get [Getters.GET_SEARCH_PRINCIPAL_LIST](): Principal[] {
    return this.principals;
  }

  /**
   * Get all Principal
   * @returns Principal
   */
  get [Getters.GET_PRINCIPALS](): Principal[] {
    return this.principals;
  }

  /**
   * Get all Principal
   * @returns Principal
   */
  get [Getters.GET_PRINCIPAL](): Principal {
    return this.principal as unknown as Principal;
  }

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_PRINCIPAL_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource list error
   */
  get [Getters.GET_PRINCIPAL_LIST_ERROR](): StoreError {
    return this.principalListError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_PRINCIPAL_LIST_LOADING](): StoreError {
    return this.principalListLoading as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_PRINCIPAL_LOADING](): StoreError {
    return this.principalLoading as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_ALL_PRINCIPALS](principals) {
    this.allPrincipals = principals;
  }

  @Mutation
  [Mutations.SET_PRINCIPAL](Principal) {
    this.principal = Principal;
  }

  @Mutation
  [Mutations.SET_PRINCIPALS](Principals) {
    this.principals = Principals;
  }

  @Mutation
  [Mutations.SET_SEARCH_PRINCIPAL_LIST](Principals) {
    this.principals = Principals;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.SEARCH_PRINCIPAL](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/principals', '', {
        params: { ...params },
      } as AxiosRequestConfig)
        .then((response) => {
          this.context.commit(
            Mutations.SET_SEARCH_PRINCIPAL_LIST,
            response.data.data.Principals
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PRINCIPAL]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/account/profile')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRINCIPAL, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_LIST_ERROR, response.data);
          reject();
        });
    });
  }
}
