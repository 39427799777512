import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/ForgotPasswordEnums';
import {
  ForgotPasswordModuleStore,
  StoreError,
  SystemError,
} from '@/models/StoreModel';

@Module
export default class ForgotPasswordModule
  extends VuexModule
  implements ForgotPasswordModuleStore
{
  actionError: StoreError | null = null;
  systemError: SystemError | null = null;

  get [Getters.GET_FORGOT_PASSWORD_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_FORGOT_PASSWORD_ACTION_ERROR](error) {
    this.actionError = error;
  }

  /**
   * Forgot password
   * @param payload
   * @returns
   */
  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/account/forgot-password', payload)
        .then(({ data }) => {
          resolve(data.message);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_FORGOT_PASSWORD_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  /**
   * Verify forgot password token
   * @param payload
   * @returns
   */
  @Action
  [Actions.FORGOT_PASSWORD_VERIFY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/account/forgot-password/verify', payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_FORGOT_PASSWORD_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  /**
   * Reset password
   * @param payload
   * @returns
   */
  @Action
  [Actions.RESET_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/account/forgot-password/reset', payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_FORGOT_PASSWORD_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }
}
