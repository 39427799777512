enum Actions {
  GENERATE_FILE_URL = 'generateFileUrl',
}

// enum Mutations {}
// // mutation types

// enum Getters {}

export {
  Actions,
  //, Mutations, Getters
};
