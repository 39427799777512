'use strict';
import { Actions, Mutations, Getters } from '@/store/enums/PaymentEnums';

import { PaymentModuleStore, StoreError } from '@/models/StoreModel';

import { Module, Action, VuexModule, Mutation } from 'vuex-module-decorators';
import ApiService from '@/core/services/ApiService';

@Module
export default class PaymentModule
  extends VuexModule
  implements PaymentModuleStore
{
  actionError: StoreError | null = null;

  sessionID = '';
  lineItems = [];

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.DO_CHECKOUT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`agent/account/plans/checkout`, payload)
        .then(({ data }) => {
          console.log('data', data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          console.log('response', response);
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CANCEL_CHECKOUT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`agent/account/checkout/cancel`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CUSTOMER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
