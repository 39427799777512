import ApiService from '@/core/services/ApiService';
import CookieService from '@/core/services/CookieService';
import JwtService from '@/core/services/JwtService';
import store from '@/store';
import { Actions, Getters, Mutations } from '@/store/enums/AuthEnums';

const LoginRouteGuard = {
  main: () => async (to, from, next) => {
    const sessionToken = CookieService.get('sessionToken');
    const sessionValid = CookieService.get('sessionValid');

    if (sessionToken && sessionValid) {
      return next({ name: 'dashboard' });
    }

    next();
  },

  isLoggedIn: () => async (to, from, next) => {
    const sessionToken = JwtService.getToken();

    if (sessionToken === null) return next();

    ApiService.setAuthorization(sessionToken);
    return await store
      .dispatch(Actions.VERIFY_AUTH)
      .then(async () => {
        const authStatus = await store.getters[Getters.GET_AUTH_STATUS];
        const authuser = await store.getters[Getters.GET_AUTH_USER];

        if (authStatus && typeof authuser.id !== 'undefined') {
          return next({ name: 'dashboard' });
        }

        if (!authStatus && to.name == 'view-tenant-review') {
          return next({
            name: 'sign-in-options',
            query: { ref: to.params.refNo },
          });
        }

        return next();
      })
      .catch(async () => {
        store.commit(
          Mutations.SET_AUTH_ROUTE_ERROR,
          'Please login to continue.'
        );

        const authStatus = await store.getters[Getters.GET_AUTH_STATUS];

        if (!authStatus && to.name == 'view-tenant-review') {
          return next({
            name: 'sign-in-options',
            query: { ref: to.params.refNo },
          });
        }

        return next();
      });
  },
};

export default LoginRouteGuard;
