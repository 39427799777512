import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations, Getters } from '@/store/enums/TemplateEnums';
import { Mutations as StoreMutations } from '@/store/enums/StoreEnums';
import { StoreError, TemplatesModuleStore } from '@/models/StoreModel';
import { Template } from '@/models/TemplateModel';
import { MailingList } from '@/models/MailingListModel';

@Module
// eslint-disable-next-line prettier/prettier
export default class TemplatesModule
  extends VuexModule
  implements TemplatesModuleStore
{
  actionError: StoreError | null = null;

  title = '';
  mailingLists = [] as Array<MailingList>;
  placeholders = [] as Array<string>;

  allTemplates = [] as Array<Template>;
  templates = [] as Array<Template>;
  template = {} as unknown as Template;

  get [Getters.GET_TITLE](): string {
    return this.title;
  }

  get [Getters.GET_MAILING_LIST](): MailingList[] {
    return this.mailingLists;
  }

  get [Getters.GET_PLACEHOLDERS](): Array<string> {
    return this.placeholders;
  }

  get [Getters.GET_TEMPLATES](): Array<Template> {
    return this.templates;
  }

  get [Getters.GET_TEMPLATE](): Template {
    return this.template;
  }

  get [Getters.GET_TEMPLATE_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_TEMPLATE_ERROR](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_TITLE](title) {
    this.title = title;
  }

  @Mutation
  [Mutations.SET_MAILING_LIST](mailers) {
    this.mailingLists = mailers;
  }

  @Mutation
  [Mutations.SET_PLACEHOLDERS](placeholders) {
    this.placeholders = placeholders;
  }

  @Mutation
  [Mutations.SET_TEMPLATES](templates) {
    this.templates = templates;
  }

  @Mutation
  [Mutations.SET_TEMPLATE](template) {
    this.template = template;
  }

  @Action
  [Actions.FETCH_MAILING_LIST]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/templates/system-names')
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_MAILING_LIST,
            data.data.mailing_lists
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(StoreMutations.SET_SYSTEM_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PLACEHOLDERS](systemName = '') {
    if (systemName === '') return;

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/placeholders/templates/${systemName}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLACEHOLDERS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TEMPLATE_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TEMPLATE]({ templateType, payload }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`agent/templates/${templateType}`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TEMPLATE, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TEMPLATE_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TEMPLATE]({ type, id, payload }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`agent/templates/${type}/${id}`, payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TEMPLATE_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TEMPLATE_LIST](templateType) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/templates/${templateType}`)
        .then((response) => {
          this.context.commit(
            Mutations.SET_TEMPLATES,
            response.data.data.templates
          );

          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TEMPLATE_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TEMPLATE_BY_ID]({ templateType, templateId }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/templates/${templateType}/${templateId}`)
        .then((response) => {
          this.context.commit(Mutations.SET_TEMPLATE, response.data.data);

          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TEMPLATE_ERROR,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TEMPLATE_BY_SYSTEM_NAME]({ templateType, systemName }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/templates/${templateType}/${systemName}`)
        .then((response) => {
          this.context.commit(Mutations.SET_TEMPLATE, response.data.data);

          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            StoreMutations.SET_SYSTEM_ERROR,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_TEMPLATE]({ templateType, reminderId }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`agent/templates/${templateType}/${reminderId}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TEMPLATE_ERROR, response.data);
          reject();
        });
    });
  }
}
