enum Actions {
  FETCH_CURRENT_SETTINGS = 'fetchCurrentSettings',
  SAVE_SETTINGS = 'saveNotificationSettings',
}

enum Mutations {
  SET_CURRENT_SETTINGS = 'setCurrentNotificationSettings',
  SET_DUE_AUTHORIZATION = 'setDueAuthorization',
  SET_MONTHLY_REPORT = 'setMonthlyReport',
  SET_ACTION_ERROR = 'setNotificationActionError',
}

enum Getters {
  GET_CURRENT_SETTINGS = 'getCurrentNotificationSettings',
  GET_DUE_AUTHORIZATION = 'getDueAuthorization',
  GET_MONTHLY_REPORT = 'getMonthlyReport',
  GET_ACTION_ERROR = 'getNotificationActionError',
}

export { Actions, Mutations, Getters };
