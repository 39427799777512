import store from '@/store';
import { Mutations as StoreMutations } from '@/store/enums/StoreEnums';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {
  ForgotPasswordRouteGuard,
  LoginRouteGuard,
  ProtectedRouteGuard,
  SubmitReviewRouteGuard,
  AccountVerificationRouteGuard,
  SubscriptionRouteGuard,
  RequestTenantReferenceGuard,
  ProfileRouteGuard,
} from './guards';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'protected-pages',
    redirect: '/sign-in',
    component: () => import('@/views/new-design/Layout.vue'),
    beforeEnter: [
      ProtectedRouteGuard.isLoggedIn(),
      RequestTenantReferenceGuard.main(),
    ],
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/new-design/Dashboard.vue'),
        beforeEnter: [
          ProtectedRouteGuard.main(),
          RequestTenantReferenceGuard.hasRequestRef(),
        ],
      },
      {
        path: '/tenant-report',
        name: 'tenant-report',
        component: () => import('@/views/new-design/TenantReport.vue'),
        beforeEnter: [
          ProtectedRouteGuard.main(),
          ProtectedRouteGuard.verified(),
        ],
        children: [
          {
            path: ':refNo',
            name: 'view-tenant-review',
            component: () => import('@/views/new-design/ViewTenantReview.vue'),
            beforeEnter: SubscriptionRouteGuard.hasSubscription(),
          },
          {
            path: 'tenant',
            name: 'view-tenant',
            component: () => import('@/views/new-design/ViewTenantReview.vue'),
            children: [
              {
                path: '',
                redirect: '/tenant-report/search',
              },
              {
                path: ':tenantId',
                name: 'view-tenant-reports',
                component: () =>
                  import('@/views/new-design/ViewTenantReview.vue'),
                beforeEnter: SubscriptionRouteGuard.hasSubscription(),
              },
            ],
          },
          {
            path: 'search',
            name: 'search-tenant-report',
            component: () => import('@/views/new-design/SearchTenant.vue'),
            beforeEnter: SubscriptionRouteGuard.hasSubscription(),
          },
          {
            path: 'submit',
            name: 'submit-tenant-review',
            component: () =>
              import('@/views/new-design/SubmitTenantReview.vue'),
            beforeEnter: [
              SubscriptionRouteGuard.hasSubscription(),
              SubmitReviewRouteGuard.main(),
            ],
          },
          {
            path: 'request',
            name: 'request-tenant-report',
            component: () =>
              import('@/views/new-design/RequestTenantReference.vue'),
            beforeEnter: SubscriptionRouteGuard.hasSubscription(),
          },
        ],
      },
      {
        path: '/profile',
        redirect: '/profile/overview',
        component: () => import('@/views/new-design/Profile.vue'),
        name: 'my-profile-over-view',
        children: [
          {
            path: 'overview',
            name: 'overview-tab',
            component: () =>
              import('@/views/new-design/pages/Profile/Overview.vue'),
          },
          {
            path: 'business-details',
            name: 'business-details-tab',
            component: () =>
              import('@/views/new-design/pages/Profile/BusinessDetails.vue'),
            beforeEnter: [ProfileRouteGuard.isPrincipal()],
          },
          {
            path: 'password-reset',
            name: 'password-reset-tab',
            component: () =>
              import('@/views/new-design/pages/Profile/PasswordReset.vue'),
          },
          {
            path: 'subscription',
            name: 'subscription-tab',
            component: () =>
              import('@/views/new-design/pages/Profile/Subscription.vue'),
          },
        ],
        beforeEnter: [ProtectedRouteGuard.main()],
      },
      {
        path: '/account/verify',
        name: 'request-account-verification-mail',
        component: () => import('@/views/VerifyAccountNotice.vue'),
        beforeEnter: [
          ProtectedRouteGuard.isNotVerified(),
          // AccountVerificationRouteGuard.request(),
        ],
      },
      {
        path: '/account/profile/update',
        name: 'complete-account-profile',
        component: () => import('@/views/new-design/CompleteProfile.vue'),
        beforeEnter: [ProtectedRouteGuard.isVerified()],
      },
      {
        path: '/settings',
        component: () => import('@/views/new-design/Settings.vue'),
        name: 'settings-page',
        beforeEnter: [ProtectedRouteGuard.main()],
        children: [
          {
            path: '',
            name: 'settings-main',
            component: () =>
              import('@/views/new-design/pages/Settings/Index.vue'),
            beforeEnter: [ProtectedRouteGuard.main()],
          },
          {
            path: 'users',
            name: 'user-namangement',
            component: () =>
              import('@/views/new-design/pages/Settings/Users.vue'),
            beforeEnter: [
              ProtectedRouteGuard.main(),
              ProtectedRouteGuard.isAdminLevel(),
            ],
          },

          {
            path: 'change-password',
            name: 'password-namangement',
            component: () =>
              import('@/views/new-design/pages/Settings/ChangePassword.vue'),
            beforeEnter: [ProtectedRouteGuard.main()],
          },
          {
            path: 'notifications',
            name: 'notification-namangement',
            component: () =>
              import('@/views/new-design/pages/Settings/Notifications.vue'),
            beforeEnter: [ProtectedRouteGuard.main()],
          },
          {
            path: 'templates',
            name: 'template-editor-page',
            redirect: { name: 'email-template-editor-page' },
            component: () =>
              import('@/views/new-design/pages/Settings/Templates.vue'),
            children: [
              {
                path: 'email',
                name: 'email-template-editor-page',
                meta: {
                  template: 'email',
                },
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/Templates/EmailEditor.vue'
                  ),
              },
              {
                path: 'sms',
                name: 'sms-template-editor-page',
                meta: {
                  template: 'sms',
                },
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/Templates/SMSEditor.vue'
                  ),
              },
            ],
            beforeEnter: [
              ProtectedRouteGuard.main(),
              ProtectedRouteGuard.isAdminLevel(),
            ],
          },
          {
            path: 'reminders',
            name: 'reminder-editor-page',
            redirect: { name: 'reminder-email-template-editor-page' },
            component: () =>
              import('@/views/new-design/pages/Settings/Reminders.vue'),
            children: [
              {
                path: 'email',
                name: 'reminder-email-template-editor-page',
                meta: {
                  template: 'email',
                },
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/Reminders/EmailEditor.vue'
                  ),
              },
              {
                path: 'sms',
                name: 'reminder-sms-template-editor-page',
                meta: {
                  template: 'sms',
                },
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/Reminders/SMSEditor.vue'
                  ),
              },
            ],
            beforeEnter: [
              ProtectedRouteGuard.main(),
              ProtectedRouteGuard.isAdminLevel(),
            ],
          },
        ],
      },
      {
        path: '/plan',
        name: 'plan-page',
        component: () => import('@/views/pages/plan/Plan.vue'),
      },
      {
        path: '/success',
        name: 'checkout-success-page',
        component: () => import('@/views/pages/plan/CheckoutSuccess.vue'),
      },
      {
        path: '/cancel',
        name: 'checkout-cancel-page',
        component: () => import('@/views/pages/plan/CheckoutCancel.vue'),
      },
    ],
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('@/views/new-design/SignIn.vue'),
    beforeEnter: [LoginRouteGuard.isLoggedIn()],
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('@/views/new-design/SignUp.vue'),
  },
  {
    path: '/sign-in-options',
    name: 'sign-in-options',
    component: () => import('@/views/new-design/SignInOptions.vue'),
  },
  {
    path: '/reference-request/submit',
    name: 'tenant-reference-request',
    component: () => import('@/views/new-design/SubmitReportSignUpOption.vue'),
    beforeEnter: [
      LoginRouteGuard.main(),
      RequestTenantReferenceGuard.refVerified(),
    ],
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/new-design/ForgotPassword.vue'),
  },

  {
    path: '/reset-password',
    name: 'reset-password-page',
    component: () => import('@/views/new-design/ResetPassword.vue'),
    children: [
      {
        path: ':token',
        name: 'verify-reset-password-token',
        component: () => import('@/views/new-design/ResetPassword.vue'),
        beforeEnter: ForgotPasswordRouteGuard,
      },
    ],
  },
  {
    path: '/account',
    name: 'acccount-layout',
    component: () => import('@/views/new-design/NoAuthLayout.vue'),
    children: [
      {
        path: 'password-reset',
        name: 'reset-account-password',
        component: () =>
          import('@/components/forms/forgot-password/ResetPasswordForm.vue'),
      },
      {
        path: 'verify',
        name: 'account-verification-notification-page',
        component: () => import('@/views/new-design/Verify.vue'),
        children: [
          {
            path: ':token',
            name: 'validate-account-verify-token',
            component: async () => {
              return import('@/views/account/verification/VerifyToken.vue');
            },
            beforeEnter: AccountVerificationRouteGuard.token(),
          },
          // {
          //   path: 'request',
          //   name: 'request-new-token',
          //   component: async () => {
          //     return import('@/views/account/RequestNewToken.vue');
          //   },
          //   beforeEnter: AccountVerificationRouteGuard.request(),
          // },
          // {
          //   path: 'renew',
          //   name: 'resend-verify-account-page',
          //   component: async () => {
          //     return import('@/views/account/ResendVerificationCode.vue');
          //   },
          // },
        ],
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/views/new-design/NoAuthLayout.vue'),
    children: [
      {
        path: 'contact-us',
        name: 'contact-us',
        component: () => import('@/views/new-design/ContactUs.vue'),
      },
      {
        path: 'contact-us/:ticketNo',
        name: 'view-support-ticket',
        component: () => import('@/views/new-design/ViewSupportTicket.vue'),
      },
      {
        path: '/terms-conditions',
        name: 'terms-conditions',
        component: () => import('@/views/new-design/TermsConditions.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/views/new-design/PrivacyPolicy.vue'),
      },
      {
        path: '/disclaimer',
        name: 'disclaimer',
        component: () => import('@/views/new-design/Disclaimer.vue'),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(StoreMutations.RESET_LAYOUT_CONFIG);

  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
