import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreError, BusinessModuleStore } from '@/models/StoreModel';
import { Actions, Getters, Mutations } from '@/store/enums/BusinessEnums';
import {
  Actions as AuthActions,
  Mutations as AuthMutations,
} from '@/store/enums/AuthEnums';
import { AxiosRequestConfig } from 'axios';

@Module
export default class BusinessModule
  extends VuexModule
  implements BusinessModuleStore
{
  actionError: StoreError | null = null;

  businessLogo = '';
  agentBusinessLogo = '';

  get [Getters.GET_BUSINESS_LOGO](): string {
    return this.businessLogo;
  }

  get [Getters.GET_AGENT_BUSINESS_LOGO](): string {
    return this.agentBusinessLogo;
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_BUSINESS_LOGO](businessLogo) {
    this.businessLogo = businessLogo;
  }

  @Mutation
  [Mutations.SET_AGENT_BUSINESS_LOGO](businessLogo) {
    this.agentBusinessLogo = businessLogo;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](actionError) {
    this.actionError = actionError as StoreError;
  }

  @Action
  [Actions.ADD_BUSINESS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/account/business', payload)
        .then(({ data }) => {
          this.context.commit(AuthMutations.SET_AUTH_USER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_BUSINESS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put('agent/account/business', payload)
        .then(({ data }) => {
          this.context.commit(AuthMutations.SET_AUTH_USER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_BUSINESS_LINKS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put('agent/account/business/links', payload)
        .then(({ data }) => {
          this.context.commit(AuthMutations.SET_AUTH_USER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_BUSINESS_LOGO]() {
    return new Promise<void>((resolve, reject) => {
      const axiosRequestConfig = {
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;
      ApiService.query('agent/account/business/logo', axiosRequestConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_AGENT_BUSINESS_LOGO](businessId) {
    return new Promise<void>((resolve, reject) => {
      const axiosRequestConfig = {
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;
      ApiService.query(`agent/business/${businessId}/logo`, axiosRequestConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.SEARCH_AGENCY](params) {
    return new Promise((resolve, reject) => {
      ApiService.parameters(params);
      ApiService.get(`agent/agencies`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }
}
