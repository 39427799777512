enum Actions {
  // action types
  LOG_RECENT_VIEWED_HISTORY = 'logRecentViewedHistory',
}

enum Mutations {
  SET_ACTION_ERROR = 'setLogActionError',
}

enum Getters {
  GET_ACTION_ERROR = 'getLogActionError',
}

export { Actions, Mutations, Getters };
