import ApiService from '@/core/services/ApiService';
import { Actions } from '@/store/enums/VerifyAccountEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreError, VerifyAccountModuleStore } from '@/models/StoreModel';
import { Getters, Mutations } from '@/store/enums/VerifyAccountEnums';
import { Mutations as AuthMutations } from '@/store/enums/AuthEnums';
import { Mutations as UserMutations } from '@/store/enums/UserEnums';

@Module
export default class VerifyAccountModule
  extends VuexModule
  implements VerifyAccountModuleStore
{
  actionError: StoreError | null = null;
  validToken = false;
  verifyAccountToken = '';

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  get [Getters.GET_VERIFY_ACCOUNT_TOKEN](): string {
    return this.verifyAccountToken;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_VALID_VERIFY_TOKEN](token) {
    this.verifyAccountToken = token;
  }

  @Action
  [Actions.VERIFY_ACCOUNT_TOKEN](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/account/verify/token', payload)
        .then(({ data }) => {
          this.context.commit(AuthMutations.SET_AUTH, data.data);
          this.context.commit(AuthMutations.SET_AUTH_TOKEN, data.data.token);
          this.context.commit(AuthMutations.SET_AUTH_TOKEN, data.data.token);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_ACCOUNT_CODE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/account/verify', payload)
        .then(({ data }) => {
          this.context.commit(AuthMutations.SET_AUTH_TOKEN, data.token);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.RESEND_VERIFICATION_EMAIL]() {
    return new Promise<void>((resolve, reject) => {
      const headers = {
        'Content-type': 'application/json',
      };
      ApiService.setCommonHeader(headers);
      ApiService.post('agent/account/verify/request')
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          console.log('VERIFY_ACCOUNT_CODE', response);
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQUEST_NEW_TOKEN]() {
    return new Promise<void>((resolve, reject) => {
      const headers = {
        'Content-type': 'application/json',
      };
      ApiService.setCommonHeader(headers);
      ApiService.post('agent/account/verify/request')
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
