import ApiService from '@/core/services/ApiService';
import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';

import { Tenant } from '@/models/TenantModel';
import { TenantModuleStore, StoreError } from '@/models/StoreModel';

import { Actions, Getters, Mutations } from '../enums/TenantEnums';
import { AxiosRequestConfig } from 'axios';

@Module
export default class TenantModule
  extends VuexModule
  implements TenantModuleStore
{
  actionError: StoreError | null = null;
  searchActionError: StoreError | null = null;

  allTenants = [];
  tenant = {} as unknown as Tenant;
  existingTenant = {} as unknown as Tenant;
  tenants = [];

  pagination = null;
  tenantListError = null;
  tenantLoading = false;
  tenantListLoading = false;

  /**
   * Get all tenant
   * @returns Tenant
   */
  get [Getters.GET_ALL_TENANTS](): Tenant[] {
    return this.allTenants;
  }

  /**
   * Get all tenant
   * @returns Tenant
   */
  get [Getters.GET_SEARCH_TENANT_LIST](): Tenant[] {
    return this.tenants;
  }

  /**
   * Get all tenant
   * @returns Tenant
   */
  get [Getters.GET_TENANTS](): Tenant[] {
    return this.tenants;
  }

  /**
   * Get all tenant
   * @returns Tenant
   */
  get [Getters.GET_TENANT](): Tenant {
    return this.tenant as unknown as Tenant;
  }
  /**
   * Get existing tenant
   * @returns Tenant
   */
  get [Getters.GET_EXISTING_TENANT](): Tenant {
    return this.existingTenant as unknown as Tenant;
  }

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_TENANT_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource list error
   */
  get [Getters.GET_TENANT_LIST_ERROR](): StoreError {
    return this.tenantListError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_LIST_LOADING](): StoreError {
    return this.tenantListLoading as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_LOADING](): StoreError {
    return this.tenantLoading as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_SEARCH_TENANT_ACTION_ERROR](): StoreError {
    return this.searchActionError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_ALL_TENANTS](tenants) {
    this.allTenants = tenants;
  }

  @Mutation
  [Mutations.SET_TENANT](tenant) {
    this.tenant = tenant;
  }

  @Mutation
  [Mutations.SET_EXISTING_TENANT](tenant) {
    this.existingTenant = tenant;
  }

  @Mutation
  [Mutations.SET_TENANTS](tenants) {
    this.tenants = tenants;
  }

  @Mutation
  [Mutations.SET_SEARCH_TENANT_LIST](tenants) {
    this.tenants = tenants;
  }

  @Mutation
  [Mutations.SET_SEARCH_TENANT_ACTION_ERROR](error) {
    this.searchActionError = error;
  }

  @Action
  [Actions.SEARCH_TENANT](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/tenants', '', {
        params: { ...params },
      } as AxiosRequestConfig)
        .then((response) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_LIST,
            response.data.data.tenants
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FIND_EXISTING_TENANT](payload, config = {} as AxiosRequestConfig) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('agent/tenants', payload, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EXISTING_TENANT, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FIND_EXISTING_TENANT_BY_ID](id: string) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/tenants', id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TENANT, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  // @Action
  // [Actions.SUBMIT_TENANT_REFERENCE](payload) {
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post('agent/tenants/reviews', payload)
  //       .then((response) => {
  //         this.context.commit(
  //           TenantMutations.SET_TENANT_RECORDS,
  //           get(response, 'data.data')
  //         );
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(
  //           Mutations.SET_ERROR,
  //           response.data.errors || response.data.message
  //         );
  //         reject();
  //       });
  //   });
  // }

  // @Action
  // [Actions.SEARCH_TANANT_RECORDS](payload: TenantPayload) {
  //   return new Promise<void>((resolve, reject) => {
  //     const { first_name, last_name, date_of_birth } = payload;

  //     ApiService.get(
  //       `agent/tenants?first_name=${first_name}&last_name=${last_name}&date_of_birth=${date_of_birth}&`
  //     )
  //       .then((response) => {
  //         this.context.commit(
  //           TenantMutations.SET_TENANT_RECORDS,
  //           get(response, 'data.data', {})
  //         );
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.message);
  //         reject();
  //       });
  //   });
  // }

  @Action
  [Actions.FETCH_TENANT](id: string) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/tenants', id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TENANT, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TENANT_LIST_ERROR, response.data);
          reject();
        });
    });
  }

  // @Action
  // [Actions.FETCH_TENANT_QUESTIONS]() {
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.get('agent/tenants/reviews/questions')
  //       .then((response) => {
  //         this.context.commit(
  //           TenantMutations.SET_TENANT_QUESTIONS,
  //           get(response, 'data.data')
  //         );
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.message);
  //         reject();
  //       });
  //   });
  // }

  // @Action
  // [Actions.FETCH_TANANTS](id: string) {
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.get(`agent/tenants/${id}/reviews`)
  //       .then((response) => {
  //         const reviews = get(
  //           response,
  //           'data.data.tenants'
  //         ) as Array<Tenants>;

  //         // Set mutation for tenant [reviews/rating reviews, tenant history]
  //         this.context.commit(TenantMutations.SET_TENANTS, reviews);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.message);
  //         reject();
  //       });
  //   });
  // }

  // @Action
  // [Actions.SEND_TENANT_REQUEST](payload) {
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post('agent/tenants/reviews/requests', payload)
  //       .then(() => {
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.message);
  //         reject();
  //       });
  //   });
  // }

  // @Action
  // [Actions.SEND_TENANT_EMAILS](payload) {
  //   return new Promise<void>((resolve, reject) => {
  //     const reviewRefNo = get(this, 'tenantRecord.reference_no', '');
  //     ApiService.post(`agent/tenants/reviews/${reviewRefNo}/send`, payload)
  //       .then(() => {
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.message);
  //         reject();
  //       });
  //   });
  // }
  // RECENT ADDED REFERENCES
  viewedHistory = [];

  get [Getters.GET_VIEWED_HISTORY](): any {
    return this.viewedHistory;
  }

  @Mutation
  [Mutations.SET_VIEWED_HISTORY](data: any) {
    this.viewedHistory = data;
  }

  @Action
  [Actions.FETCH_VIEWED_HISTORY]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`agent/references/viewed`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_VIEWED_HISTORY, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }
}
