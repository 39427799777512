import objectPath from 'object-path';
import merge from 'deepmerge';
import layoutConfig from '@/core/config/DefaultLayoutConfig';
import { Mutations, Getters } from '@/store/enums/StoreEnums';
import { Mutation, Module, VuexModule } from 'vuex-module-decorators';
import LayoutConfigTypes from '@/core/config/LayoutConfigTypes';

interface StoreInfo {
  config: LayoutConfigTypes;
  initial: LayoutConfigTypes;
  backgroundColor: string;
  textColor: string;
  avatarBackground: string;
}

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
  config = layoutConfig;
  initial = layoutConfig;
  backgroundColor = '';
  textColor = '#000000';
  avatarBackground = '';

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get layoutConfig() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }

  get [Getters.GET_DEFAULT_BACKGROUND_COLOR](): string {
    return this.backgroundColor;
  }

  get [Getters.GET_DEFAULT_TEXT_COLOR](): string {
    return this.textColor;
  }

  get [Getters.GET_DEFAULT_AVATAR_BGCOLOR](): string {
    return this.avatarBackground;
  }

  @Mutation
  [Mutations.SET_LAYOUT_CONFIG](payload): void {
    this.config = payload;
  }

  @Mutation
  [Mutations.RESET_LAYOUT_CONFIG]() {
    this.config = Object.assign({}, this.initial);
  }

  @Mutation
  [Mutations.OVERRIDE_LAYOUT_CONFIG](): void {
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem('config') || '{}')
    );
  }

  @Mutation
  [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](payload): void {
    this.config = merge(this.config, payload);
  }

  @Mutation
  [Mutations.SET_DEFAULT_BACKGROUND_COLOR](): void {
    this.backgroundColor = process.env.VUE_APP_BACKGROUND_COLOR;
  }

  @Mutation
  [Mutations.SET_DEFAULT_TEXT_COLOR](): void {
    this.backgroundColor = process.env.VUE_APP_TEXT_COLOR;
  }

  @Mutation
  [Mutations.SET_DEFAULT_AVATAR_BGCOLOR](): void {
    this.avatarBackground = process.env.VUE_APP_AVATAR_BGCOLOR;
  }
}
