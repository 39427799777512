enum Actions {
  PLAN = 'accountPlan',
  FETCH_PLAN_PRODUCT = 'fetchPlanProduct',
}

enum Mutations {
  SET_PLAN = 'setPlan',
  SET_PLAN_PRODUCT = 'setPlanProduct',
  SET_ACTION_ERROR = 'setPlanActionError',
}

enum Getters {
  GET_PLAN = 'getPlan',
  GET_PLAN_PRODUCT = 'getPlanProduct',
  GET_ACTION_ERROR = 'getPlanActionError',
}

export { Actions, Mutations, Getters };
