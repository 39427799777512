import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/NotificationEnums';
import { NotificationModuleStore, StoreError } from '@/models/StoreModel';

@Module
export default class NotificationModule
  extends VuexModule
  implements NotificationModuleStore
{
  notificationSettings = [];

  dueAuthorization = null;
  monthlyReport = null;

  actionError: StoreError | null = null;

  get [Getters.GET_DUE_AUTHORIZATION]() {
    return this.dueAuthorization;
  }

  get [Getters.GET_MONTHLY_REPORT]() {
    return this.monthlyReport;
  }

  get [Getters.GET_CURRENT_SETTINGS]() {
    return this.notificationSettings;
  }

  get [Getters.GET_ACTION_ERROR]() {
    return this.actionError;
  }

  @Mutation
  [Mutations.SET_CURRENT_SETTINGS](settings) {
    this.notificationSettings = settings;
  }

  @Mutation
  [Mutations.SET_DUE_AUTHORIZATION](data) {
    this.dueAuthorization = data;
  }

  @Mutation
  [Mutations.SET_MONTHLY_REPORT](data) {
    this.monthlyReport = data;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  /**
   * Send Support Ticket
   * @param payload
   * @returns
   */
  @Action
  [Actions.FETCH_CURRENT_SETTINGS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('agent/notifications')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_SETTINGS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  /**
   * Save
   * @param payload
   * @returns
   */
  @Action
  [Actions.SAVE_SETTINGS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`agent/notifications/${payload.systemName}`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
