import { ReviewAnswer } from './ReviewAnswerModel';
import { Tenant } from './TenantModel';
import { User } from './UserModel';

export enum Roles {
  TENANT = 'Tenant',
  PRINCIPAL = 'principal',
  PM = 'property_manager',
  ADMIN = 'Admin',
}

export interface TenantReview {
  id?: string;
  roles: Roles;
  reference_no?: string;
  tenant_id?: string;
  tenant?: Tenant;

  // Step 1: Tenant Details
  email: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  date_of_birth: string;

  // Step 2: Rental Property Details
  address_line: string;
  state: string;
  city: string;
  country: string;
  post_code: string;

  move_out_date: string;
  move_in_date: string;
  rent_per_week?: string;

  // Step 3: Review Answers
  review_answers: ReviewAnswer[];
  average_rating: string;

  // Step 4: Ledger
  ledger_file: string;
  request_reference_id: string;

  latitude: string;
  longitude: string;
  map_street_src: string;
  map_static_src: string;

  user?: User;
  status?: string;
  is_published?: string;
  is_flagged?: string;
  is_not_reviewed?: string;

  date_created?: string;
}
