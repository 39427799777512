enum Actions {
  FETCH_USERS = 'fetachUsers',
  CREATE_NEW_USER = 'createNewUser',
  UPDATE_USER = 'updateUser',
  DELETE_USER = 'deleteUser',
  FETCH_AVATAR = 'fetchAvatar',
  RESTORE_USER = 'restoreUser',
}

enum Mutations {
  SET_ALL_USERS = 'setAllUsers',
  SET_USER = 'setUser',
  SET_USERS = 'setUsers',
  SET_USER_LIST_LOADING = 'setUserListLoading',
  SET_USER_LOADING = 'setUserLoading',
  SET_USER_LIST_ERROR = 'setUserListError',
  SET_USER_ACTION_ERROR = 'setUserActionError',
  SET_AVATAR = 'setAvatar',
  SET_USER_PAGINATION = 'setUserPagination',
}

enum Getters {
  GET_ALL_USERS = 'getAllUsers',
  GET_USERS = 'getUsers',
  GET_USER = 'getUser',
  GET_USER_LIST_ERROR = 'getUserListError',
  GET_USER_LIST_LOADING = 'getUserListLoading',
  GET_USER_LOADING = 'getUserLoading',
  GET_USER_ACTION_ERROR = 'getUserActionError',
  GET_AVATAR = 'getAvatar',
  GET_USER_PAGINATION = 'getUserPagination',
}

export { Actions, Mutations, Getters };
